:root {
  /* --primary-color: #007F87; */
  --primary-color: #00A9B1;
  --secondary-color: #C3D8FF;

  --tertiary-color: rgba(195, 216, 255, 0.5);

  --background: #f9f9f9

  /* --primary-hover: rgba(0, 127, 135, 0.8); */
  --primary-hover: rgba(255, 195, 0, 0.8);

  --secondary-hover: rgba(195, 216, 255, 0.8);
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
