.header {
  flex: 0.15;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
  min-height: 4rem;
  background-color: white;
  color: var(--primary-color);
}
.hd-side-container-title {
  flex: 1;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.hd-side-container-title h2 {
  cursor: pointer;
  color: white;
  font-weight: 400;
  padding: 0.1rem 0.5rem;
  border-radius: 0.1rem;
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--secondary-color)
  );
}
.hd-sd-ct-link {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 400;
}
.hd-menu-container {
  display: none;
}
.hd-side-container-menu {
  visibility: visible;
  flex: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: 1.5rem;
}
.hd-menu-icon {
  cursor: pointer;
}
.hd-button-svg {
  background: none;
  border: none;
  color: white;
}
.hd-menu-container-ul li {
  padding: 0.5rem;
}

@media screen and (min-width: 826px) {
  .hd-side-container-title {
    flex: 0.1;
  }
  .hd-menu-container {
    flex: 0.8;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    visibility: visible;
    padding-bottom: 0.3rem;
  }
  .hd-menu-container-ul {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    list-style-type: none;
  }
  .hd-link {
    text-decoration: none;
    color: var(--primary-color);
    font-size: 1.1rem;
    font-weight: 400;
    padding: 0.5rem;
  }
  .selected {
    text-decoration: underline;
  }
  .hd-link:hover {
    text-decoration: underline;
  }
  .hd-side-container-menu {
    display: flex;
    flex: 0.1;
    position: relative;
    visibility: hidden;
  }
}