.answer-container {
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-top: 0.3rem;
  gap: 0.5em;
  cursor: pointer;
  border-radius: 0.2rem;
}
.ac-check-box {
  flex: 0.1;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ac-check-box input {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.ac-cb-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid rgb(167, 167, 167);
  border-radius: 0.2rem;
  background-color: white;
  color: black;
}
.ac-cb-box-checked {
  background-color: rgb(13, 189, 21);
  border-color: rgb(13, 189, 21);
  color: white;
}
.ac-cb-box-unchecked {
  background-color: white;
}
.ac-text-box {
  flex: 1;
  display: flex;
  align-items: center;
}
.ac-text-box p {
  font-size: 1rem;
  font-weight: 300;
}

@media screen and (max-width: 826px) {
  .ac-text-box p {
    font-size: 0.9rem;
  }
  .ac-check-box input {
    width: 1.2rem;
    height: 1.2rem;
  }
}