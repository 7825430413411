.navbar-background {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.navbar {
  height: 80px;
  position: absolute;
  right: 0;
  justify-content: start;
  align-items: center;
  display: none;
}
.menu-bars {
  margin-right: 1rem;
  font-size: 1.8rem;
}
.nav-menu {
  background-color: white;
  width: 70%;
  max-width: 20rem;
  padding-left: 1rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 350ms;
}
.nav-menu.active {
  right: 0;
  transition: 350ms;
}
.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0 8px 0px;
  list-style: none;
  height: 60px;
}
.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  background-color: rgb(241, 241, 241);
}

.nav-text a:hover {
  background-color: var(--secondary-color);
}
.nav-menu-items {
  width: 100%;
  padding: 0;
}
.navbar-toggle {
  /* background-color: var(--navBg); */
  background-color: white;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
span {
  margin-left: 16px;
}
@media screen and (max-width: 826px) {
  .navbar {
    display: flex;
  }
  
}