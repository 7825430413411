.test-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}
.tc-info-text {
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 2rem;
}
.tc-qestions-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}
.tc-button {
  width: 12rem;
  height: 2.8rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0.2rem;
  margin-top: 2.5rem;
}
.tc-button:hover {
  background-color: #009BA5;
  transition: 0.1s;
}
.tc-result-container {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 95%;
  padding-top: 2rem;
}
.tc-result-container p {
  font-size: 1.5rem;
  font-weight: 500;
}
@media screen and (max-width: 1000px) {
  .tc-result-container  {
    gap: 1rem;
  }
  .tc-result-container p {
    font-size: 1.7rem;
  }
  .tc-info-text {
    font-size: 1rem;
    margin-top: 1rem;
    text-align: center;
  }
}
@media screen and (max-width: 826px) {
  .tc-result-container  {
    gap: 1rem;
  }
}