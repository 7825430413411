.rates-container {
  width: 55rem;
  max-width: 55rem;
  min-height: 20rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
  cursor: pointer;
  transition: box-shadow 0.1s ease;
}
.rates-slices-container {
  width: 100%;
  height: 100%;
  min-height: 9px;
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
  margin-top: 0.2rem;
}
.rates-slices-container-none {
  visibility: hidden;
}
.rates-pie-chart-container {
  width: 90%;
  height: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.rpcc-chart-container {
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
}
.rpcc-text-container {
  flex: 1;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

}
.pcc-tc-table-container {
  width: 90%;
  height: 10rem;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}
.pcc-tc-tb-table{
  width: 90%;
}
.pcc-tc-tb-t-body tr {
  border: 1px solid #ddd;
}
.pcc-tc-tb-t-b-header {
  font-size: 1rem;
  font-weight: 600;
  color: #555;
}
.pcc-tc-tb-t-b-data {
  font-size: 0.9rem;
  font-weight: 600;
  color: #555;
}
.pcc-tc-tb-t-b-header {
  font-size: 1rem;
  font-weight: 600;
  color: #555;
}
.aa {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 900px) {
  .rates-container {
    width: 100%;
  }
  .rates-pie-chart-container {
    flex-direction: column;
    gap: 0.5rem;
  }
  .pcc-tc-title {
    font-size: 0.9rem;
  }
  .pcc-tc-table-container  {
    width: 100%;
  }
}