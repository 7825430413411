.rates-progress-container {
  width: 100%;
  height: 0.4rem;
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
}
.rpc-slice {
  flex: 1;
  width: 50%;
  height: 0.4rem;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2rem;
}
.rpc-slice-progress {
  flex: 1;
  width: 0%;
  height: 0.4rem;
  background-color: rgba(0, 0, 0, 0.5);
  transition: width .1s linear;
  border-radius: 2rem;
}