.pr-container {
  width: 100%;
  max-width: 55rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.pr-c-content-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 10rem;
  gap: 1rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
}
.pr-c-cb-title {
  font-size: 1.2rem;
  font-weight: 400;
}
.pr-c-cb-text {
  font-size: 2.5rem;
  font-weight: 500;
}
@media screen and (max-width: 826px) {
  .pr-container {
    flex-direction: column;
    height: 22rem;
  }
  .pr-c-cb-title {
    font-size: 1rem;
  }
  .pr-c-cb-text {
    font-size: 2.2rem;
  }
}