.pie-container{
  width: 100%;
  height: 30rem;
  max-height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1000px) {
  .pie-container {
    width: 100%;
  }
}