.container-home {
  flex: 1;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 5rem 0;
}
.ch-title-box {
  width: 90%;
  text-align: center;
}
.ch-title-box > p {
  font-size: 1rem;
  font-weight: 400;
  align-self: center;
}
.ch-content-box {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 90%;
}
/* .ch-content-box {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}
@media screen and (max-width: 1099px) {
  .ch-title-box {
    width: 90%;
  }
  .ch-title-box > p {
    font-size: 0.9rem;
  }
  .ch-content-box {
    margin-top: 2rem;
  }
}
*/
@media screen and (max-width: 826px) {
  .ch-title-box p {
    font-size: 0.9rem;
  }
} 