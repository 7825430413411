.results-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rc-content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70rem;
  margin: 1rem 0;
}
.rc-cb-title-box {
  margin-bottom: 0.5rem;
}
hr {
  width: 100%;
  margin: 1rem 0;
  border: 1px solid rgb(220, 220, 220);
}
.compare-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cc-filter-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  gap: 0.5rem;
}
.cc-fb-element-box {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  overflow-y: auto;
}
.cc-fb-element {
  background-color: var(--secondary-color);
  min-width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: background-color 0.1s ease;
}
.cc-fb-element:hover {
  background-color: var(--secondary-hover);
}
.cc-fb-add-element {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: background-color 0.1s ease;
}
.cc-fb-add-element:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.chart-container {
  width: 100%;
  min-height: 30em;
  max-height: 40em;
  display: flex;
  justify-content: center;
}
.cc-table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}
@media screen and (max-width: 1000px) {
  .rc-content-box {
    width: 90%;
  }
}
@media screen and (max-width: 720px) {
  .cc-fb-element-box {
    max-width: 18rem;
  }
  .cc-fb-element {
    min-width: 5rem;
    font-size: 0.8rem;
  }
}
