.footer {
  background-color: rgb(255, 255, 255);
  color: #000;
  padding: 10px;
  text-align: center;
  border-top: 1px solid #eaeaea;
}
.footer p {
  font-size: 0.9rem;
  font-weight: 400;
}