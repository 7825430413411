.App {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.menu-box {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.body {
  flex: 1;
  display: flex;
  height: 100vh;
  flex-direction: column;
}
