.progress-bar {
  position: relative;
  height: 20px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  text-align: center;
}
.progress-bar-fix {
  position: fixed;
  top: 0;
}
.progress-bar-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 20px;
  background-color: rgb(13, 189, 21);
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  transition: width 0.5s ease;
}
.deneme {
  position: absolute;
  width: 100%;
  color: white;
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .progress-bar {
    width: 100%;
  }
}