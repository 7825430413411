.container-test-redirect {
  max-width: 55rem;
  display: flex;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
}
.ctr-image-box {
  background-color: var(--secondary-color);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem 0 0 0.2rem;
}
.ctr-image-box img {
  width: 90%;
  height: 90%;
  object-fit: contain;
}
.ctr-content-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 1rem;
}
.ctr-content-box-title {
  font-size: 1.2rem;
  font-weight: 600;
}
.ctr-content-box-description {
  font-size: 1rem;
  font-weight: 400;
}
.ctr-button {
  width: 13rem;
  height: 2.8rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0.2rem;
}
.ctr-button:hover {
  background-color: #0097A0;
  transition: 0.1s;
}
.ctr-cb-reference-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}
.ctr-cb-rc-title {
  font-size: 0.9rem;
  color: #828282;
  margin-top: 0.5rem;
}
.ctr-cb-rc-link {
  font-size: 1rem;
  color: #828282;
  margin-top: 0.5rem;
  width: 3rem;
}
@media screen and (max-width: 826px) {
  .container-test-redirect {
    flex-direction: column;
  }
  .ctr-image-box {
    border-radius: 0.2rem 0.2rem 0 0;
  }
  .ctr-image-box img {
    height: 20rem;
  }
  .ctr-content-box {
    padding: 1rem;
    align-items: center;
    gap: 1rem;
    text-align: center;
  }
  .ctr-content-box-title {
    font-size: 1.1rem;
  }
  .ctr-content-box-description {
    font-size: 0.9rem;
  }
  .ctr-button {
    width: 80%;
    font-size: 0.8rem;
  }
  .ctr-cb-reference-container {
    align-items: center;
  }
}