table {
  border-collapse:collapse;
  width: 100%;
}
th {
  padding:0.5rem 2rem;
}
td {
  padding:0.5rem;
  border-top: 1px solid #ddd;
}
thead {
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
}
.tc-columns {
  position: sticky;
  top: 1.8rem;
  background-color: #f5f5f5;
}
.tc-columns th {
  font-size: 1rem;
  font-weight: 500;
}
th::first-letter {
  text-transform: uppercase;
}
.table-container {
  width: 100%;
  max-height: 25rem;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
}
.table-container-single {
  width: 100%;
  max-height: 25rem;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
}
.tc-columns-single {
  background-color: #f5f5f5;
}

.table-container::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.3rem;
}
.table-container::-webkit-scrollbar-track {
  border-radius: 0.2rem;
}
.table-container::-webkit-scrollbar-thumb {
  background-color: rgb(67, 67, 67);
  border-radius: 0.2rem;
}

@media screen and (max-width: 826px) {
  th {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
  td {
    font-size: 0.9rem;
  }
  .tc-columns th {
    font-size: 0.9rem;
  }
}