.question-container {
  width: 60rem;
  margin-top: 1.5em;
}
.qc-question-box {
  border: 1px solid rgb(167, 167, 167);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 0.2rem;
}
.qc-qb-number {
  flex: 0.1;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qc-qb-number p {
  background-color: var(--secondary-color);
  border-radius: 0.2rem;
  padding: 0.4rem 0.8rem;
  font-size: 1.4rem;
  color: white;
  font-weight: bold;
}
.qc-qb-text {
  flex: 1.4;
  display: flex;
  align-items: center;
}
.qc-qb-t-image {
  width: 100%;
  /* border: 1px solid black; */
}
.qc-qb-text p {
  font-size: 1rem;
  font-weight: 400;
}

@media screen and (max-width: 1000px) {
  .question-container {
    width: 95%;
  }
  .qc-question-box {
    flex-direction: column;
    gap: 1em;
  }
  .qc-qb-number p {
    padding: 0.5rem 0.9rem;
  }
}
@media screen and (max-width: 826px) {
  .qc-question-box {
    padding: 0.5rem 0.1rem;
  }
  .qc-qb-number p {
    padding: 0.4rem 0.7rem;
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }
  .qc-qb-text {
    font-size: 0.9rem;
  }
}