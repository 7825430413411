.demographic-container {
  width: 60rem;
  margin-top: 1.5em;
  border: 1px solid rgb(167, 167, 167);
  /* padding: 1rem; */
  border-radius: 0.2rem;
}
.dc-question-box {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  border-radius: 0.2rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  padding-right: 2rem;
}
.dc-question-box p {
  flex: 1;
  font-size: 1.2rem;
  font-weight: 500;
}
.dc-qb-search-box {
  flex: 1;
  width: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border: 1px solid rgb(167, 167, 167);
  border-radius: 0.2rem;
  justify-content: space-between;
  padding: 0 0.5rem;
}
.dc-qb-search-box input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem;
}
.dc-answer-container {
  /* min-height: 13rem; */
  max-height: 26rem;
  overflow-y: auto;
  padding: 1rem;
}
.dc-answer-container::-webkit-scrollbar {
  width: 0.6rem;
}
.dc-answer-container::-webkit-scrollbar-track {
  border-radius: 0.2rem;
}
.dc-answer-container::-webkit-scrollbar-thumb {
  background-color: white;
  border: 1px solid rgb(167, 167, 167);
  border-radius: 0.2rem;
}
.dc-answer-box {
  /* background-color: white; */
  border: 1px solid rgb(167, 167, 167);
  display: flex;
  flex-direction: row;
  margin-top: 0.3rem;
  gap: 0.5em;
  cursor: pointer;
  border-radius: 0.2rem;
  padding-left: 1rem;
}
label {
  cursor: pointer;
  width: 100%;
  flex: 1rem;
  padding: 1rem;
}
@media screen and (max-width: 1000px) {
  .demographic-container {
    width: 95%;
  }
}
@media screen and (max-width: 826px) {
  .dc-question-box {
    flex-direction: column;
    padding-right: 1rem;
  }
  .dc-qb-search-box {
    flex: 1;
    width: 80%;
    min-width: 13rem;
  }
}