.popup-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.7);
  z-index: 10;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.pc-active {
  display: flex;
}
.pc-content-box {
  background-color: rgb(246, 246, 246);
  width: 50%;
  max-height: 60%;
  max-width: 50rem;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
.pc-cb-title-box {
  width: 100%;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0;
}
.pc-cb-tb-title {
  font-size: 1.1rem;
  font-weight: 600;
}
.pc-cb-tb-information-box {
  width: 100%;
  display: flex;
  gap: 0.2rem;
  font-weight: 300;
  font-size: 0.9rem;
  align-items: center;
  padding: 0.5rem 0;
}
.pc-cb-tb-search-box {
  flex: 0.5;
  width: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgb(167, 167, 167);
  border-radius: 0.2rem;
  justify-content: space-between;
  padding: 0 0.5rem;
}
.pc-cb-tb-search-box input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem;
  background-color: rgb(246, 246, 246);
}
.pc-cb-contents {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.pc-cb-contents::-webkit-scrollbar {
  width: 0.4em;
}
.pc-cb-contents::-webkit-scrollbar-track {
  background-color: rgb(219, 219, 219);
  border-radius: 10px
}
.pc-cb-contents::-webkit-scrollbar-thumb {
  background-color: rgb(66, 66, 66);
  border-radius: 10px
}
.pc-cb-c-element {
  padding: 1rem;
  margin: 0.2rem 0;
  border-radius: 0.2rem;
  border: 1px solid rgb(167, 167, 167);
  cursor: pointer;
  transition: background-color 0.1s ease;
}
.current-element {
  background-color: var(--secondary-color);
}
.pc-cb-c-element:hover {
  background-color: var(--secondary-color);
}
.pc-close-button {
  border: none;
  background-color: white;
  padding: 0.8rem 1rem;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: background-color 0.1s ease;
}
.pc-close-button:hover {
  background-color: var(--secondary-color);
}

@media screen and (max-width: 826px) {
  .pc-content-box {
    width: 80%;
  }
  .pc-cb-title-box {
    flex-direction: column;
    gap: 0.5rem;
  }
  .pc-cb-tb-information-box {
    justify-content: center;
  }
  .pc-cb-tb-search-box {
    width: 95%;
  }
}